import { defineStore } from "pinia";
import axios from 'axios';
import { useRuntimeConfig } from "nuxt/app";
import { useValidationStore } from '~/stores/account/validations.store';

export const useCustomerStore = defineStore("customer", {
  state: () => ({
  }),
  getters: {
    accountApiUrl: () => {
      const config = useRuntimeConfig();
      return config.public.vcrmAccountApiUrl as string;
    },
    appLocale: () => useNuxtApp().$i18n.locale.value || 'en',
  },
  actions: {
    getVcrmAccountToken() {
      if (!document) {
        return false;
      }
      const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
      if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
        return vue_cookie.split("=")[1];
      }
    },
    async fetchCustomers(page: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.get(`${this.accountApiUrl}/customers?page=${page || 1}`, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
          "Application-Language": this.appLocale,
        }
      })
        .then((res) => {
          return res.data
        })
    },
    async fetchCustomer(customer_id: string) {
      const vue_cookie = this.getVcrmAccountToken();

      if (!vue_cookie) {
        return;
      }

      return await axios.get(`${this.accountApiUrl}/customers/${customer_id}`, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
          "Application-Language": this.appLocale,
        }
      })
        .then((res) => {
          return res.data
        })
    },
    async addCustomer(form: any) {
      const validationStore = useValidationStore();
      const vue_cookie = this.getVcrmAccountToken();
      const localePath = useLocalePath();
      const router = useRouter();

      if (!vue_cookie) {
        return;
      }

      return await axios.post(`${this.accountApiUrl}/customers`, form, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
          "Application-Language": this.appLocale,
        }
      })
        .then((res) => {
          router.push({ path: localePath({ name: 'account.customers.show', params: { id: res.data.id } }) })
        }).catch((res) => {
          if (res.status == 422) {
            validationStore.setValidationsAction(res?.response?.data)
          }
        })
    },
    async updateCustomer(form: any, customer_id: string) {
      const validationStore = useValidationStore();
      const vue_cookie = this.getVcrmAccountToken();
      const localePath = useLocalePath();
      const router = useRouter();

      if (!vue_cookie) {
        return;
      }

      return await axios.put(`${this.accountApiUrl}/customers/${customer_id}`, form, {
        headers: {
          "Authorization": `Bearer ${vue_cookie}`,
          "Application-Platform": "web",
          "Application-Language": this.appLocale,
        }
      })
        .then((res) => {
          router.push({ path: localePath({ name: 'account.customers.show', params: { id: customer_id } }) })
        }).catch((res) => {
          if (res.status == 422) {
            validationStore.setValidationsAction(res?.response?.data)
          }
        });
    },
  }
});